import { Helmet } from "react-helmet-async"
import React, { useState, useCallback, useEffect } from "react"
// @mui
import {
  Box,
  Card,
  Table,
  Stack,
  TableBody,
  Popover,
  Container,
  Typography,
  Tab,
  TableContainer,
  TablePagination,
  Tabs,
  MenuItem,
  Button,
  Tooltip,
  TablePaginationOwnProps,
  CircularProgress,
  InputAdornment,
  TextField,
  IconButton,
  TableCell,
  TableRow,
} from "@mui/material"
// sections
import {
  ArchiveOutlined,
  Cancel,
  FilterAltOffOutlined,
  FilterAltOutlined,
  InfoOutlined,
  MailOutlined,
  Send,
} from "@mui/icons-material"
import { usePaginated } from "src/queries/usePaginated"
import { useNavigate } from "react-router-dom"
import Label from "src/components/label"
import { useTheme } from "@mui/material"
import { alpha } from "@mui/material/styles"
import { useCashApplicationCount } from "src/queries/cod/useCashApplicationCount"
import { confirm } from "src/components/confirm"
import { usePatchCashApplication } from "src/queries/cod/usePatchCashApplication"
import { useSnackbar } from "notistack"
import {
  CheckOutlined,
  NotInterestedOutlined,
  PageviewOutlined,
} from "@mui/icons-material"
// import NewApplicationForm from "src/sections/@dashboard/credit/NewApplicationForm"
// import ManualApplicationStartModal from "src/sections/@dashboard/credit/ManualApplicationStartModal"
import { useUser } from "src/queries"
import { info } from "src/utils/logger"
import { Group } from "src/types/base.types"
import {
  IApplicationTableFilterValue,
  IApplicationListFilters,
} from "src/types/application.types"
import { useUsers } from "src/queries/base/useUsers"
import { CashApplication } from "src/types"
import { APPLICATION_STAGE } from "src/statics"
import ListRow from "src/sections/@dashboard/cod/list/ListRow"
import ApplicationListToolbar from "src/sections/@dashboard/components/ApplicationListToolbar"
import ApplicationListHead from "src/sections/@dashboard/components/ApplicationListHead"
import ApplicationLink from "src/sections/@dashboard/components/ApplicationLink"
import NewApplicationForm from "src/sections/@dashboard/credit/NewApplicationForm"
import SendCashCompletionReminderDialog from "src/sections/@dashboard/cod/components/SendCashCompletionReminderDialog"
import useDebounced from "src/hooks/useDebounced"
import Iconify from "src/components/iconify"
import LoadingIcon from "src/assets/icons/loading-icon"
// mock

// ----------------------------------------------------------------------

const LastAccessdLabel = (
  <Tooltip
    title="This is the time this application was last accessed by the applicant."
    placement="bottom"
    arrow
  >
    <Box
      style={{
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      }}
    >
      Last Accessed
      <InfoOutlined
        fontSize="small"
        style={{
          marginLeft: "0.2rem",
        }}
      />
    </Box>
  </Tooltip>
)

const TABLE_HEAD = [
  { id: "submitter", label: "Submitter", alignRight: false },
  { id: "date", label: "Date", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "assignee", label: "Credit Manager", alignRight: false },
  { id: "sales_rep", label: "Sales Rep", alignRight: false },
  // { id: "terms", label: "Terms", alignRight: false },
  // { id: "references", label: "Trade References", alignRight: false },
  { id: "last_accessed", label: LastAccessdLabel, alignRight: false },
  { id: "actions" },
]

const STATUS_OPTIONS = [
  { value: "all", label: "All" },
  { value: 1, label: "Incomplete" },
  { value: 50, label: "Manual" },
  { value: 100, label: "Awaiting Signature" },
  { value: 200, label: "Completed By Client" },
  { value: 800, label: "Approved" },
  { value: 900, label: "Rejected" },
]

const defaultFilters: IApplicationListFilters = {
  status: "all",
  sales_rep: [],
  assignee: [],
  store: [],
  q: "",
}

export default () => {
  const navigate = useNavigate()

  const [page, setPage] = useState(0)

  const { isCreditManager } = useUser()

  const { data: users } = useUsers()

  const [rowsPerPage, setRowsPerPage] = useState(10)

  const { enqueueSnackbar } = useSnackbar()

  const { execute: patchApplication } = usePatchCashApplication(() => {
    setOpenMenu(null)
    refetch()
    enqueueSnackbar("Application updated", { variant: "success" })
  })
  const [openModal, setOpenModal] = useState<string | null>(null)

  const handleCloseModal = () => {
    setOpenModal(null)
  }

  const [openMenu, setOpenMenu] = useState<
    React.MouseEvent<HTMLButtonElement>["currentTarget"] | null
  >(null)

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenMenu(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setOpenMenu(null)
  }

  const [filters, setFilters] = useState(defaultFilters)
  const showArchive = filters.status === APPLICATION_STAGE.ARCHIVE
  const theme = useTheme()

  const { data: applicationCount, refetch: refetchApplicationCount } =
    useCashApplicationCount()
  const [showReminderDialog, setShowReminderDialog] = useState(false)
  const [searchQuery, setSearchQuery] = useState("")
  const [previousSearchQuery, setPreviousSearchQuery] = useState("")
  const debouncedSearchTerm = useDebounced(searchQuery)
  const filterConfig = {
    stage: filters.status !== "all" ? filters.status : undefined,
    email_verified: true,
    sales_rep:
      filters.sales_rep.length > 0
        ? filters.sales_rep.map((u) => u.id)
        : undefined,
    assignee:
      filters.assignee.length > 0
        ? filters.assignee.map((u) => u.id)
        : undefined,
    q: debouncedSearchTerm,
  }
  const {
    data,
    refetch: refetchPaginated,
    isFetching,
  } = usePaginated("cod/", page, filterConfig)

  const isLoadingCashApplications = () => {
    if (isFetching || !data) {
      return true
    }
    if (isFetching && searchQuery !== previousSearchQuery) {
      return true
    }
    return false
  }
  const refetch = useCallback(() => {
    refetchApplicationCount()
    refetchPaginated()
  }, [refetchApplicationCount, refetchPaginated])

  useEffect(() => {
    refetchPaginated()
  }, [filters, refetchPaginated, debouncedSearchTerm])

  useEffect(() => {
    if (data) {
      setPreviousSearchQuery(searchQuery)
    }
  }, [data])

  const handleFilters = useCallback(
    (name: string, value: IApplicationTableFilterValue) => {
      setPage(0)
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    },
    [],
  )

  const handleFilterStatus = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      handleFilters("status", newValue)
      setPage(0)
    },
    [handleFilters],
  )

  const apps = (data?.results as CashApplication[]) || []

  const handleChangePage = (
    _: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage: TablePaginationOwnProps["onRowsPerPageChange"] =
    function (event) {
      setPage(0)
      setRowsPerPage(parseInt(event.target.value, 10))
    }

  const FILTERED_HEADS = TABLE_HEAD
  const handleSearch = useCallback(
    (event: any) => {
      setSearchQuery(event.target.value)
      setPage(0)
    },
    [setSearchQuery, setPage],
  )

  const handleArchive = () => {
    if (!showArchive) {
      handleFilters("status", APPLICATION_STAGE.ARCHIVE)
      setPage(0)
    } else {
      handleFilters("status", "all")
      setPage(0)
    }
  }

  return (
    <>
      <Helmet>
        <title>Cash-on-Delivery (COD) Application</title>
      </Helmet>

      <Container maxWidth={false}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Cash-on-Delivery (COD) Applications
          </Typography>
          <Stack alignItems="end" gap="8px">
            <ApplicationLink type="cash" />
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              gap="8px"
            >
              <Button
                color="primary"
                variant="contained"
                endIcon={<Send />}
                sx={{ textTransform: "capitalize" }}
                onClick={() => setOpenModal("INVITE")}
              >
                Invite New Applicant
              </Button>
              {/* {isCreditManager && (
                <Button
                  color="primary"
                  variant="contained"
                  endIcon={<PostAdd />}
                  sx={{ textTransform: "capitalize" }}
                  onClick={() => setOpenModal("MANUAL")}
                >
                  Start Manual Application
                </Button>
              )} */}
            </Stack>
          </Stack>
        </Stack>

        <Card>
          {APPLICATION_STAGE.ARCHIVE !== filters.status ? (
            <>
              <Tabs
                value={filters.status}
                onChange={handleFilterStatus}
                sx={{
                  px: 2.5,
                  boxShadow: (theme) =>
                    `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
                }}
              >
                {STATUS_OPTIONS.map((tab) => (
                  <Tab
                    key={tab.value}
                    iconPosition="end"
                    value={tab.value}
                    label={tab.label}
                    icon={
                      <Label
                        variant={
                          ((tab.value === "all" ||
                            tab.value === filters.status) &&
                            "filled") ||
                          "soft"
                        }
                        color={
                          (tab.value === 800 && "success") ||
                          ((tab.value as number) < 800 && "warning") ||
                          (tab.value === 900 && "error") ||
                          "default"
                        }
                      >
                        {tab.value === "all" && applicationCount
                          ? applicationCount?.incomplete +
                            applicationCount?.awaitingSignature +
                            applicationCount?.completedByClient +
                            applicationCount?.approved +
                            applicationCount?.rejected +
                            applicationCount?.manual
                          : ""}
                        {tab.value === 1 && applicationCount?.incomplete}
                        {tab.value === 100 &&
                          applicationCount?.awaitingSignature}
                        {tab.value === 200 &&
                          applicationCount?.completedByClient}
                        {tab.value === 800 && applicationCount?.approved}
                        {tab.value === 900 && applicationCount?.rejected}
                        {tab.value === 50 && applicationCount?.manual}
                      </Label>
                    }
                  />
                ))}
              </Tabs>
            </>
          ) : (
            <></>
          )}
          <Stack
            spacing={2}
            alignItems={{ xs: "flex-end", md: "center" }}
            direction={{
              xs: "column",
              md: "row",
            }}
            sx={{
              p: 2.5,
              pr: { xs: 2.5, md: 1 },
            }}
          >
            {isCreditManager && (
              <>
                <FilterAltOutlined />

                <ApplicationListToolbar
                  title="Sales Rep"
                  field="sales_rep"
                  values={filters.sales_rep}
                  onFilters={handleFilters}
                  //
                  options={
                    users
                      ?.filter((user) => user.groups?.includes(Group.Sales))
                      ?.sort((a, b) => {
                        if (!a.firstName) {
                          return 1
                        }
                        if (!b.firstName) {
                          return -1
                        }
                        return a.firstName < b.firstName ? -1 : 1
                      }) || []
                  }
                />
                <ApplicationListToolbar
                  title="Credit Manager"
                  field="assignee"
                  values={filters.assignee}
                  onFilters={handleFilters}
                  options={
                    users
                      ?.filter(
                        (user) => user.groups?.includes(Group.CreditManager),
                      )
                      ?.sort((a, b) => {
                        if (!a.firstName) {
                          return 1
                        }
                        if (!b.firstName) {
                          return -1
                        }
                        return a.firstName < b.firstName ? -1 : 1
                      }) || []
                  }
                />
                {((filters.assignee && filters.assignee.length > 0) ||
                  (filters.sales_rep && filters.sales_rep.length > 0)) && (
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<FilterAltOffOutlined />}
                    aria-label="remove filters"
                    onClick={() => {
                      const value = { ...defaultFilters }
                      if (filters.status === APPLICATION_STAGE.ARCHIVE) {
                        value.status = APPLICATION_STAGE.ARCHIVE
                      }
                      setFilters(value)
                    }}
                  >
                    Remove Filters
                  </Button>
                )}
              </>
            )}
            <TextField
              autoFocus
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify
                      icon="eva:search-fill"
                      width={24}
                      sx={{ color: "text.disabled" }}
                    />
                  </InputAdornment>
                ),
                endAdornment: (
                  <IconButton
                    onClick={() => {
                      setSearchQuery("")
                      setPage(0)
                    }}
                  >
                    {searchQuery ? (
                      <Cancel sx={{ color: "error.dark" }} />
                    ) : (
                      <></>
                    )}
                  </IconButton>
                ),
              }}
              inputProps={{
                sx: { typography: "body2" },
              }}
            />
            {isCreditManager && (
              <Button
                variant="contained"
                color={showArchive ? "primary" : "inherit"}
                aria-label={
                  showArchive ? "Show Active Applications" : "Show Archived"
                }
                style={{ marginLeft: "auto" }}
                onClick={handleArchive}
              >
                {showArchive ? "Show Active Applications" : "Show Archived"}
              </Button>
            )}
          </Stack>

          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <ApplicationListHead
                headLabel={FILTERED_HEADS}
                rowCount={1}
                numSelected={0}
              />

              <TableBody>
                {isLoadingCashApplications() && (
                  <TableRow
                    hover
                    key={123}
                    tabIndex={-1}
                    style={{ width: "100%" }}
                  >
                    <TableCell colSpan={FILTERED_HEADS.length}>
                      <Box
                        style={{
                          justifyContent: "center",
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          padding: 1,
                        }}
                      >
                        <LoadingIcon />
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
                {!isLoadingCashApplications() &&
                  apps &&
                  apps.slice(0, rowsPerPage).map((row, index: number) => {
                    return (
                      <ListRow
                        row={row}
                        key={index}
                        index={index}
                        handleOpenMenu={handleOpenMenu}
                      />
                    )
                  })}
              </TableBody>
            </Table>
          </TableContainer>

          {data && (
            <TablePagination
              rowsPerPageOptions={[10]}
              component="div"
              count={data.count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Card>
      </Container>

      {openMenu &&
        apps &&
        apps.find(
          (app) =>
            app.id === openMenu.getAttribute("row") &&
            app.stage !== APPLICATION_STAGE.MANUAL_INCOMPLETE,
        ) && (
          <Popover
            open={Boolean(openMenu)}
            anchorEl={openMenu}
            onClose={handleCloseMenu}
            anchorOrigin={{ vertical: "top", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            PaperProps={{
              sx: {
                p: 1,
                width: 170,
                "& .MuiMenuItem-root": {
                  px: 1,
                  typography: "body2",
                  borderRadius: 0.75,
                },
              },
            }}
          >
            <MenuItem
              id="view-button"
              onClick={() => {
                navigate(
                  "/cash-applications/" + openMenu?.getAttribute("row") + "/",
                )
              }}
            >
              <Box style={{ display: "flex", gap: "1rem" }}>
                <PageviewOutlined />
                View
              </Box>
            </MenuItem>
            {openMenu &&
              apps &&
              apps.find((app) => {
                return (
                  app.id === openMenu.getAttribute("row") &&
                  (app.stage == APPLICATION_STAGE.ONE ||
                    app.stage == APPLICATION_STAGE.AWAITING_SIGNATURE)
                )
              }) && (
                <MenuItem
                  onClick={() => {
                    setShowReminderDialog(true)
                  }}
                >
                  <Box style={{ display: "flex", gap: "1rem" }}>
                    <MailOutlined />
                    Send Reminder
                  </Box>
                </MenuItem>
              )}
            {openMenu &&
              apps &&
              apps.find(
                (app) =>
                  app.id === openMenu.getAttribute("row") &&
                  app.stage != APPLICATION_STAGE.ARCHIVE,
              ) && (
                <MenuItem
                  onClick={() => {
                    confirm("You are about to archive this application.", {
                      okColor: "error",
                      okLabel: "Archive",
                    })
                      .then(
                        () => {
                          return patchApplication(
                            openMenu?.getAttribute("row") || "",
                            {
                              stage: APPLICATION_STAGE.ARCHIVE,
                            },
                          )
                        },
                        () => {
                          info("cancelled")
                        },
                      )
                      .catch(() => {
                        info("cancelled")
                      })
                  }}
                >
                  <Box style={{ display: "flex", gap: "1rem" }}>
                    <ArchiveOutlined />
                    Archive
                  </Box>
                </MenuItem>
              )}
            {openMenu &&
              apps &&
              apps.find(
                (app) =>
                  app.id === openMenu.getAttribute("row") &&
                  app.stage < APPLICATION_STAGE.APPROVED &&
                  app.stage >= APPLICATION_STAGE.MANUAL_COMPLETE,
              ) && (
                <>
                  <MenuItem
                    onClick={() => {
                      confirm("You are about to approve this application.", {
                        okLabel: "Approve",
                      })
                        .then(
                          () => {
                            return patchApplication(
                              openMenu?.getAttribute("row") || "",
                              {
                                stage: APPLICATION_STAGE.APPROVED,
                              },
                            )
                          },
                          () => {
                            info("cancelled")
                          },
                        )
                        .catch(() => {
                          info("cancelled")
                        })
                    }}
                  >
                    <Box style={{ display: "flex", gap: "1rem" }}>
                      <CheckOutlined />
                      Approve
                    </Box>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      confirm("You are about to reject this application.", {
                        okColor: "error",
                        okLabel: "Reject",
                      })
                        .then(
                          () => {
                            return patchApplication(
                              openMenu?.getAttribute("row") || "",
                              {
                                stage: APPLICATION_STAGE.REJECTED,
                              },
                            )
                          },
                          () => {
                            info("cancelled")
                          },
                        )
                        .catch(() => {
                          info("cancelled")
                        })
                    }}
                  >
                    <Box style={{ display: "flex", gap: "1rem" }}>
                      <NotInterestedOutlined />
                      Reject
                    </Box>
                  </MenuItem>
                </>
              )}
          </Popover>
        )}
      {showReminderDialog && (
        <SendCashCompletionReminderDialog
          application={
            apps.find((app) => {
              return app.id === openMenu?.getAttribute("row")
            }) as CashApplication
          }
          onClose={() => {
            setShowReminderDialog(false)
            handleCloseMenu()
          }}
        />
      )}

      {openMenu &&
        apps &&
        apps.find(
          (app) =>
            app.id === openMenu.getAttribute("row") &&
            app.stage === APPLICATION_STAGE.MANUAL_INCOMPLETE,
        ) && (
          <Button
            variant="contained"
            onClick={() => {
              navigate(
                "/manual-application/" + openMenu.getAttribute("row") + "/",
              )
            }}
          >
            Continue
          </Button>
        )}

      {openModal == "INVITE" && (
        <NewApplicationForm
          open={openModal == "INVITE"}
          toggle={handleCloseModal}
          refetch={refetch}
          type="cash"
        />
      )}
      {/* <ManualApplicationStartModal
        open={openModal == "MANUAL"}
        toggle={handleCloseModal}
        refetch={refetch}
      /> */}
    </>
  )
}
