import React, { useState } from "react"
import { DrawOutlined, PersonAddOutlined } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"

import {
  Box,
  Typography,
  TextField,
  FormControl,
  FormControlLabel,
  FormLabel,
  Divider,
  useMediaQuery,
  RadioGroup,
  Radio,
  Button,
  Alert,
  Checkbox,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@mui/material"

import { ApplicationTemplate, Application, CreditApplication } from "src/types"
import SignaturePad from "react-signature-pad-wrapper"

import MobileNavButtonsBlock from "src/components/mobile-nav-buttons-block/MobileNavButtonsBlock"

import { useAddCollaborator } from "src/queries/credit/useAddCollaborator"
import { usePostAnalytics } from "src/queries/analytics/usePostAnalytics"

import { isEmail } from "src/utils/utils"
import { useAnonymousBusiness } from "src/queries/credit/useAnonymousBusiness"
import { pdf } from "@react-pdf/renderer"
import PDFBaseApplicationOutput from "src/components/pdf/output-template/PDFBaseApplicationOutput"
import { getPdfTemplate } from "src/components/pdf/output-template/template"
import { PDFDocument } from "pdf-lib"

type Props = {
  application?: Application
  onContinue: () => void
  onChange: (key: string, value: any) => void
  template?: ApplicationTemplate
  handleBack: () => void
  activeStep: number
}

export default ({
  application,
  onContinue,
  onChange,
  template,
  handleBack,
  activeStep,
}: Props) => {
  const [signerEmail, setSignerEmail] = useState("")
  const [isSigner, setIsSigner] = useState<number | undefined>(undefined)
  const [signerFirstName, setSignerFirstName] = useState("")
  const [signerLastName, setSignerLastName] = useState("")
  const [signerRole, setSignerRole] = useState("")
  const [complete, setComplete] = useState(false)
  const [electronicTermsAccepted, setElectronicTermsAccepted] = useState(false)
  const [electronicTermsDialogOpen, setElectronicTermsOpen] = useState(false)

  const { execute: addCollaborator, isLoading } = useAddCollaborator()
  const { execute: saveAnalytics } = usePostAnalytics()

  const { data: businessData } = useAnonymousBusiness(
    application?.seller?.id || "",
  )

  const matches = useMediaQuery("(max-width: 899px)")
  const matchesXL = useMediaQuery("(max-width: 1199px)")

  // const { error, data: visitorData } = useVisitorData(
  //   { extendedResult: true },
  //   { immediate: true },
  // )

  const ref = React.useRef<SignaturePad | null>(null)

  const onClearSignature = () => {
    ref.current?.clear()
  }

  const [pdfBlob, setPdfBlob] = useState<string | null>(null)

  React.useEffect(() => {
    if (businessData && template) {
      pdf(
        <PDFBaseApplicationOutput
          data={application as CreditApplication}
          businessData={businessData}
          customQuestionsTemplate={template?.customFields}
          pdfTemplate={getPdfTemplate(template)}
        />,
      )
        .toBlob()
        .then(async (blob) => {
          const existingPdfBytes = await fetch(
            businessData?.terms as string,
            // eslint-disable-next-line promise/no-nesting
          ).then((res) => res.arrayBuffer())

          const appPdfArrayBuffer = await blob.arrayBuffer()

          const docsToMerge = [existingPdfBytes, appPdfArrayBuffer]
          const mergedPdf = await PDFDocument.create()

          for (const doc of docsToMerge) {
            const pdfDoc = await PDFDocument.load(doc)
            const copiedPages = await mergedPdf.copyPages(
              pdfDoc,
              pdfDoc.getPageIndices(),
            )
            copiedPages.forEach((page) => mergedPdf.addPage(page))
          }

          const pdfBytes = await mergedPdf.save()

          const docUrl = URL.createObjectURL(
            new File([pdfBytes], "Credit Application.pdf", {
              type: "application/pdf",
            }),
          )
          return setPdfBlob(docUrl)
        })
        .catch((e) => {
          console.error(e)
        })
    }
  }, [businessData, template, application])

  const onButtonClicked = () => {
    if (isSigner === 0 && signerEmail && application?.id) {
      addCollaborator(application.id, signerEmail, true, () => {
        saveAnalytics(
          "collaborator_invite",
          "credit",
          application.id || "",
          "Collaborator Invited",
          { signerEmail: signerEmail },
        )
        setComplete(true)
      })
    } else if (isSigner === 1) {
      if (ref.current?.isEmpty()) {
        window.alert("Please draw your signature in the designated box.")
        return
      }
      const signature = ref.current?.toDataURL() as string
      onChange("signerFirstName", signerFirstName)
      onChange("signerLastName", signerLastName)
      onChange("signerRole", signerRole)
      onChange("signature", signature)
      onContinue()
    }
  }

  return (
    <>
      {!matchesXL && (
        <Typography component="h1" variant="h5" style={{ marginTop: "8px" }}>
          Review and Sign
        </Typography>
      )}

      {!complete && (
        <>
          <Typography variant="body2" style={{ margin: "8px 0 8px 0" }}>
            We require a digital signature to finalize your application. By
            clicking continue you confirm that you are the authorized signer
            (usually Owner, Prinicipal, C-Suite or similar). If you are not
            authorized to sign the credit application, please invite the
            authorized person to sign. They will receive a link via email to
            continue the process.
          </Typography>
          <Typography variant="body2" style={{ margin: "8px 0 8px 0" }}>
            <b>
              Please Note: your application is not complete until you press the
              Sign button.
            </b>
          </Typography>
          {!matches && <Divider style={{ margin: "16px 0 16px 0" }} />}
          <FormControl>
            <FormLabel
              id="signature-raio-label"
              style={{ margin: "8px 0 8px 0" }}
            >
              Choose one of the options below:
            </FormLabel>
            <RadioGroup
              aria-labelledby="signature-raio-label"
              defaultValue={undefined}
              name="radio-buttons-group"
              value={isSigner}
              onChange={(e) => setIsSigner(Number(e.target.value))}
            >
              <FormControlLabel
                value={1}
                control={<Radio />}
                label="I am the authorized signer (Owner, Prinicipal, C-Suite or similar)."
              />
              {matches && <Divider style={{ margin: "8px 0 8px 0" }} />}
              <FormControlLabel
                value={0}
                control={<Radio />}
                label="I am not the authorized signer. I will invite the authorized signer to sign."
              />
            </RadioGroup>
          </FormControl>
          {isSigner === 0 && (
            <TextField
              autoFocus
              margin="dense"
              id="signer-email"
              label="Signer Email"
              type="email"
              value={signerEmail}
              onChange={(event) => {
                setSignerEmail(event.target.value)
              }}
              fullWidth
            />
          )}
          {isSigner === 1 && (
            <>
              <Typography
                component="h1"
                variant="h5"
                style={{ marginTop: "8px" }}
              >
                Credit Agreement
              </Typography>
              <Alert severity="info" style={{ margin: "8px 0 8px 0" }}>
                Please review {businessData?.name} Credit Terms and Conditions
                and verify your application information below. Once reviewed,
                scroll to the bottom of the page to enter your legal first and
                last name as well as your role, draw your signature on the pad
                below and <b>click Sign</b>. You will receive a copy of this
                application via email. By signing this application, you agree to
                these terms, you agree that you have the authorization to sign
                the application and confirm that all the questions in this
                application have been answered turthfully.
              </Alert>
              {pdfBlob && (
                <iframe src={pdfBlob as string} width="100%" height="500px" />
              )}
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  gap: "32px",
                  alignItems: "center",
                  flexDirection: matches ? "column" : "row",
                }}
              >
                <TextField
                  id="signer-first-name"
                  fullWidth
                  margin="normal"
                  label="First Name"
                  type="text"
                  required
                  value={signerFirstName}
                  onChange={(event) => {
                    setSignerFirstName(event.target.value)
                  }}
                />
                <TextField
                  id="signer-last-name"
                  required
                  margin="normal"
                  fullWidth
                  label="Last Name"
                  type="text"
                  value={signerLastName}
                  onChange={(event) => {
                    setSignerLastName(event.target.value)
                  }}
                />
                <TextField
                  id="signer-role"
                  margin="normal"
                  required
                  fullWidth
                  label="Role"
                  type="text"
                  value={signerRole}
                  onChange={(event) => {
                    setSignerRole(event.target.value)
                  }}
                />
              </Box>

              <Typography style={{ margin: "4px" }}>Signature *</Typography>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  border: "solid 3px",
                  borderRadius: "8px",
                  borderColor: "#aaaaaa",
                  margin: "16px 0 16px 0",
                  height: "250px",
                }}
              >
                <SignaturePad
                  ref={ref}
                  height={250}
                  options={{
                    minWidth: 5,
                    maxWidth: 5,
                    penColor: "rgb(0, 0, 0)",
                  }}
                  redrawOnResize={true}
                />
              </Box>
              <Button
                fullWidth
                onClick={onClearSignature}
                style={{ margin: "16px 0 16px 0" }}
              >
                Clear Signature
              </Button>
            </>
          )}
        </>
      )}

      {complete && (
        <Typography variant="body2" style={{ margin: "8px 0 8px 0" }}>
          An email has been sent to the authorized signer with a link to
          complete the application. Please note that the application is not
          considered complete until it is signed by the authorized signer. You
          can close this window now.
        </Typography>
      )}

      {!complete && (
        <>
          {isSigner === 1 && (
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "left",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    id="terms-checkbox"
                    value="remember"
                    sx={{ color: "#D0D5DD" }}
                    onChange={(event) => {
                      setElectronicTermsAccepted(event.target.checked)
                    }}
                    checked={electronicTermsAccepted}
                  />
                }
                label={
                  <div>
                    <Typography>
                      I confirm that I have read and understood the{" "}
                      <a onClick={() => setElectronicTermsOpen(true)}>
                        <u>Electronic Record and Signature Disclosure</u>
                      </a>{" "}
                      and consent to use electronic records and signatures.
                    </Typography>
                  </div>
                }
              />
            </Box>
          )}

          {matches ? (
            <MobileNavButtonsBlock
              onContinue={() => {
                onButtonClicked()
              }}
              loading={isLoading}
              disable={
                isSigner === undefined ||
                (isSigner === 0 && !isEmail(signerEmail)) ||
                (isSigner === 1 &&
                  (!signerFirstName ||
                    !signerLastName ||
                    !signerRole ||
                    !electronicTermsAccepted))
              }
              handleBack={handleBack}
              activeStep={activeStep}
              continueText={isSigner !== 1 ? "Invite" : "Sign"}
              click={() => {
                onButtonClicked()
              }}
            />
          ) : (
            <LoadingButton
              id="submit-sign"
              onClick={() => {
                onButtonClicked()
              }}
              loading={isLoading}
              size="large"
              fullWidth
              endIcon={
                isSigner !== 1 ? <PersonAddOutlined /> : <DrawOutlined />
              }
              disabled={
                isSigner === undefined ||
                (isSigner === 0 && !isEmail(signerEmail)) ||
                (isSigner === 1 &&
                  (!signerFirstName ||
                    !signerLastName ||
                    !signerRole ||
                    !electronicTermsAccepted))
              }
              variant="contained"
              sx={{ mt: 3, mb: 2, textTransform: "none" }}
            >
              {isSigner !== 1 ? "Invite" : "Sign"}
            </LoadingButton>
          )}
        </>
      )}
      <Dialog
        open={electronicTermsDialogOpen}
        onClose={() => setElectronicTermsOpen(false)}
      >
        <DialogTitle>Electronic Record and Signature Disclosure</DialogTitle>
        <DialogContent>
          Please read the following information carefully. By clicking Sign, you
          agree that you have reviewed the following terms and conditions and
          consent to transact business electronically using NetNow electronic
          signature system. If you do not agree to these terms, do not click the
          'Sign' button. Please note that NetNow ("we", "us" or "Company") and
          any of our clients with whom NetNow is acting as a service provider
          for the purposes of offering digital trade credit applications will
          send all documents electronically to you to the email address that you
          have given us during the course of the business relationship unless
          you tell us otherwise in accordance with the procedure explained
          herein. Once you sign a document electronically, we will send a PDF
          version of the document to you. You have the right to request paper
          copies of these documents. Alternatively, you also have the ability to
          download and print these documents sent to you electronically, and
          re-upload a scanned copy of the printed and physically signed
          documents. If you, however, wish to request paper copies of these
          documents sent to you electronically, you can write to us at
          info@netnow.io. At any point in time during the course of our business
          relationship, you have the right to withdraw your consent to receive
          documents in electronic format. If you wish to withdraw your consent,
          you can decline to sign a document that we have sent to you and send
          an email to info@netnow.io informing us that you wish to receive
          documents only in paper format. If you need to change the email
          address that you use to receive notices and disclosures from us, write
          to us at info@netnow.io
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setElectronicTermsOpen(false)
            }}
          >
            Close
          </Button>
          <Button
            onClick={() => {
              setElectronicTermsAccepted(true)
              setElectronicTermsOpen(false)
            }}
          >
            Accept
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
