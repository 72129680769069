import { Box, Typography } from "@mui/material"
import { Icon } from "@iconify/react"

interface ValueChangeIndicatorProps {
  value: number // The value passed to the component to check if positive or negative
}

export default function ValueChangeIndicator({
  value,
}: ValueChangeIndicatorProps) {
  // Determine if the value is positive or negative
  const isPositive = value > 0

  // Determine the styles for positive/negative values
  const boxStyle = {
    display: "inline-flex",
    alignItems: "center",
    backgroundColor: isPositive ? "#e8f9f0" : "#feeff0", // Light green or red
    borderRadius: "6px",
    padding: "2px 2px",
  }

  const textStyle = {
    color: isPositive ? "#58d36b" : "#f45b68", // Dark green or dark red text
    fontWeight: "bold",
  }

  return (
    <Box sx={boxStyle}>
      <Icon
        icon={isPositive ? "mdi:arrow-up" : "mdi:arrow-down"} // Arrow direction based on the value
        style={{
          color: isPositive ? "#58d36b" : "#f45b68",
          marginRight: "2px",
        }}
        width={16}
      />
      <Typography variant="caption" sx={textStyle}>
        {isPositive ? `+${value}%` : `${value}%`}
      </Typography>
    </Box>
  )
}
