import { QueryClient } from "react-query"
import { useTypedMutation } from "../../useTypedMutation"

export function useSignCreditTermsStandalone() {
  const ENDPOINT = "/application/v3/sign_terms"
  const post = useTypedMutation<FormData>("post/application/v3/sign_terms")

  const execute = (
    requestId: string,
    verificationCode: string,
    signerFirst: string,
    signerLast: string,
    signerRole: string,
    fingerprintRequestId: string,
    b64Signature: string,
    onSuccess?: () => void,
  ) => {
    const formData = new FormData()
    formData.append("verificationCode", verificationCode)
    formData.append("signerFirst", signerFirst)
    formData.append("signerLast", signerLast)
    formData.append("signerRole", signerRole)
    formData.append("b64Signature", b64Signature)
    formData.append("visitorRequestId", fingerprintRequestId)
    formData.append("requestId", requestId)
    post.mutate(
      {
        method: "post",
        endpoint: ENDPOINT,
        body: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        timeout: 60000,
      },
      {
        onSuccess: async () => {
          await new QueryClient().invalidateQueries({
            queryKey: [ENDPOINT.replaceAll("/", "")],
          })
          if (onSuccess) onSuccess()
        },
      },
    )
  }
  return { ...post, execute }
}
