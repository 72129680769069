import { useCallback } from "react"
import {
  Box,
  CircularProgress,
  Typography,
  Stack,
  Paper,
  Chip,
} from "@mui/material"
import ChartColumnStacked from "../components/chart/ChartColumnStacked"
import { useRequestCreditDashboard } from "../queries/credit_dashboard/useRequestCreditDashboard"
import { Icon } from "@iconify/react"
import ValueChangeIndicator from "src/components/value-change-indicator/ValueChangeIndicator"
import { CreditDashboard } from "src/types/credit_dashboard.types"

export default function CreditDashboardPage() {
  const { data: creditDashboardData, isLoading } = useRequestCreditDashboard()

  const isPageLoading = useCallback(() => {
    if (creditDashboardData) {
      return false
    }

    if (isLoading) {
      return true
    }

    return false
  }, [creditDashboardData, isLoading])

  if (isPageLoading()) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    )
  }

  const {
    creditApplicationsInProcess,
    totalCreditApplicationsApprovedLimit,
    creditApplicationsYearly,
    sixMonthsStatusCounts,
    creditApplicationsMonthly,
    monthlyCreditApplicationsCountPercentDifference,
    creditApplicationsYearlyPercentDifference,
    yearlyTotalCreditApplicationsApprovedLimitPercentDifference,
  } = creditDashboardData as CreditDashboard

  const chartData = Object.entries(sixMonthsStatusCounts || {}).map(
    ([month, counts]: [string, any]) => ({
      month,
      approved: counts?.approved || 0,
      declined: counts?.declined || 0,
      incomplete: counts?.incomplete || 0,
      pending_Info: counts?.pendingInfo || 0,
    }),
  )

  return (
    <Box padding={3}>
      <Typography variant="h4" gutterBottom>
        Dashboard <Chip label="Beta" variant="outlined" />
      </Typography>

      {/* Summary Counts Section */}
      <Stack
        direction="row"
        spacing={2}
        sx={{ pt: 2, pb: 3 }}
        justifyContent="space-between"
      >
        <Paper
          elevation={4}
          sx={{ p: 3, minWidth: "220px", minHeight: "118px" }}
        >
          <Box display="flex" justifyContent="space-between" marginBottom={3}>
            <Icon icon="mdi:calendar-clock" width={16} />
            <Typography
              variant="caption"
              sx={{ fontWeight: "bold", marginLeft: "3px" }}
            >
              Applications - Last 30 Days
            </Typography>
          </Box>
          <Stack direction="row" spacing={1} justifyContent="space-between">
            <Typography variant="h4">
              {creditApplicationsMonthly ?? "--"}
            </Typography>
            <ValueChangeIndicator
              value={monthlyCreditApplicationsCountPercentDifference ?? 0}
            />
          </Stack>
        </Paper>
        <Paper
          elevation={4}
          sx={{ p: 3, minWidth: "220px", minHeight: "118px" }}
        >
          <Box display="flex" justifyContent="space-between" marginBottom={3}>
            <Icon icon="mdi:progress-clock" width={16} />
            <Typography
              variant="caption"
              sx={{ fontWeight: "bold", marginLeft: "2px" }}
            >
              Applications In Process
            </Typography>
          </Box>
          <Stack direction="row" spacing={1} justifyContent="center">
            <Typography variant="h5" sx={{ marginLeft: "50%" }}>
              {creditApplicationsInProcess ?? "--"}
            </Typography>
          </Stack>
        </Paper>

        <Paper
          elevation={4}
          sx={{ p: 3, minWidth: "220px", minHeight: "118px" }}
        >
          <Box display="flex" justifyContent="space-between" marginBottom={3}>
            <Icon icon="mdi:calendar-today" width={16} />
            <Typography
              variant="caption"
              sx={{
                fontWeight: "bold",
                marginRight: "28px",
                textAlign: "center",
              }}
            >
              Applications YTD
            </Typography>
          </Box>
          <Stack direction="row" spacing={1} justifyContent="space-between">
            <Typography variant="h5">
              {creditApplicationsYearly ?? "--"}
            </Typography>
            <ValueChangeIndicator
              value={creditApplicationsYearlyPercentDifference ?? 0}
            />
          </Stack>
        </Paper>

        <Paper
          elevation={4}
          sx={{ p: 3, minWidth: "220px", minHeight: "118px" }}
        >
          <Box display="flex" justifyContent="space-between" marginBottom={3}>
            <Icon icon="mdi:currency-usd" width={16} />
            <Typography
              variant="caption"
              sx={{ fontWeight: "bold", marginRight: "16px" }}
            >
              Credit Approved YTD
            </Typography>
          </Box>
          <Stack direction="row" spacing={1} justifyContent="space-between">
            <Typography variant="h5">
              {`$${totalCreditApplicationsApprovedLimit?.toLocaleString()}`}
            </Typography>
            <ValueChangeIndicator
              value={
                yearlyTotalCreditApplicationsApprovedLimitPercentDifference ?? 0
              }
            />
          </Stack>
        </Paper>
      </Stack>

      {/* Chart Section */}
      <Paper elevation={4} sx={{ p: 3 }}>
        <ChartColumnStacked
          data={chartData}
          title="Credit Applications by Month"
        />
      </Paper>
    </Box>
  )
}
