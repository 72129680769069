import { useQuery } from "react-query"
import queryString from "query-string"
import { CreditDashboard } from "src/types/credit_dashboard.types"

export function useRequestCreditDashboard() {
  return useQuery<CreditDashboard>(
    queryString.stringifyUrl({
      url: "/credit_dashboard/",
    }),
    {
      retry: (failureCount, error) => {
        if (failureCount < 1) {
          return true
        }
        // do not retry on 404
        return (error as any)?.response?.status !== 404
      },
      refetchOnWindowFocus: false,
    },
  )
}