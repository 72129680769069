import { useCallback, useMemo } from "react"
import * as Sentry from "@sentry/react"
import { CreditApplication, PersonalGuaranty } from "../../types"
import { useDocumentDetails } from "src/queries/vendors/useDocumentDetails"
import { useQuery } from "react-query"
import queryString from "query-string"
import { useAnonymousBusiness } from "./useAnonymousBusiness"
import { usePreference, BUSINESS_PREFERENCES } from "src/hooks/use-preference"
import { useDocumentV3 } from "./signing_v3/useDocumentV3"
import { useSignatureRequestDetailsV3 } from "./signing_v3/useSignatureRequestDetailsV3"
import { useUser } from "../base/useUser"

interface ApiError {
  response?: {
    status: number
  }
}

export enum ProgressState {
  NONE_SIGNED,
  ALL_SIGNED,
  SOME_SIGNED,
  EMPTY,
}

const usePersonalGuarantors = (
  application?: CreditApplication,
  personalGuaranty: PersonalGuaranty[] = [],
) => {
  const { data: fetchedGuarantors, isLoading: isFetchingGuarantors } = useQuery<
    Array<PersonalGuaranty>
  >(
    queryString.stringifyUrl({
      url: `/application/${application?.id}/personal_guaranty`,
      query: { application_id: application?.id },
    }),
    {
      enabled: !!application && application?.id?.trim() !== "",
      retry: (failureCount, error) => {
        if (failureCount < 1) {
          return true
        }
        // Don't retry on 404 errors
        return (error as ApiError)?.response?.status !== 404
      },
    },
  )
  const { data: user } = useUser()

  const { preference: hasSignatureV3 } = usePreference(
    BUSINESS_PREFERENCES.SIGNATURE_V3,
    user?.userToBusiness?.business,
  )

  const guarantorsToUse: PersonalGuaranty[] =
    fetchedGuarantors && fetchedGuarantors.length > 0
      ? fetchedGuarantors
      : personalGuaranty

  const { data: documentDetails, isLoading: isFetchingDetails } =
    useDocumentDetails(
      guarantorsToUse ? guarantorsToUse[0]?.documentRequestId : "",
      "guaranty",
    )

  const findAction = useCallback(
    (actionId: string) => {
      if (!documentDetails?.requests?.actions) return undefined

      const actions = documentDetails.requests.actions

      if (actions.length === 0) {
        Sentry.captureMessage(
          `No actions found in document details ${actionId}`,
          "error",
        )
        return undefined
      }

      if (actions.length === 1) {
        return actions[0]
      }

      return actions.find((a: { actionId: string }) => a.actionId === actionId)
    },
    [documentDetails],
  )

  const { data: documentDetailsV3, isLoading: isFetchingDetailsV3 } =
    useSignatureRequestDetailsV3(
      hasSignatureV3 && guarantorsToUse
        ? guarantorsToUse[0]?.documentRequestId
        : undefined,
    )

  const signedCount = useMemo(() => {
    if (!guarantorsToUse || guarantorsToUse.length === 0) return 0
    if (!hasSignatureV3) {
      return guarantorsToUse.filter((g) => {
        const action = findAction(g.actionId)
        return action?.actionStatus === "SIGNED"
      }).length
    }
    return documentDetailsV3?.complete ? 1 : 0
  }, [documentDetailsV3?.complete, findAction, guarantorsToUse, hasSignatureV3])

  const personalGuarantorsSignatureState = useMemo(() => {
    if (!guarantorsToUse || guarantorsToUse.length === 0)
      return ProgressState.EMPTY

    if (signedCount === 0) return ProgressState.NONE_SIGNED
    if (signedCount === guarantorsToUse.length) return ProgressState.ALL_SIGNED
    return ProgressState.SOME_SIGNED
  }, [signedCount, guarantorsToUse])

  return {
    findAction,
    personalGuarantorsSignatureState,
    isFetchingDetails: isFetchingDetails || isFetchingDetailsV3,
    isFetchingGuarantors,
    guarantorsToUse,
    signedCount,
    hasSignatureV3,
    documentDetailsV3,
  }
}

export default usePersonalGuarantors
