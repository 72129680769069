import { PersonalGuaranty } from "src/types"
import { useTypedMutation } from "../../useTypedMutation"

export function usePersonalGuarantyRequest() {
  const ENDPOINT = "/application/v3/resolve_request"
  const post = useTypedMutation<{
    visitorRequestId: string
    requestId: string
    verificationCode: string
  }>("post/application/v3/resolve_request")

  const verifyCode = (
    visitorRequestId: string,
    requestId: string,
    verificationCode: string,
    onSuccess?: (g: PersonalGuaranty) => void,
  ) => {
    post.mutate(
      {
        endpoint: ENDPOINT,
        body: {
          visitorRequestId,
          requestId,
          verificationCode,
        },
      },
      {
        onSuccess: async (data) => {
          if (onSuccess) onSuccess(data as PersonalGuaranty)
        },
      },
    )
  }
  return { ...post, verifyCode }
}
