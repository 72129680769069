import {
  Button,
  FormControl,
  FormControlLabel,
  Stack,
  Switch,
  TextField,
} from "@mui/material"
import { Formik, FormikProps } from "formik"
import { enqueueSnackbar } from "notistack"
import { usePatchCreditApplicationTemplatePage } from "src/queries/internal/usePatchCreditApplicationTemplatePage"
import {
  PAGE_LABEL_TRADE_REFERENCES,
  TRADE_REFERENCE_CONFIGS,
} from "src/sections/@dashboard/intake_sections/constants"
import { ApplicationTemplate, Config, Page } from "src/types"
import { getConfigFromPage, getPageFromTemplate } from "src/utils/utils"
import SectionWhenComponent from "../SectionWhenComponent"

export default function CreditSectionTradeReferencesDetails({
  applicationTemplate,
  refetch,
}: {
  applicationTemplate: ApplicationTemplate
  refetch: () => void
}) {
  const page = getPageFromTemplate(
    applicationTemplate,
    PAGE_LABEL_TRADE_REFERENCES,
  )

  const { execute, isLoading } = usePatchCreditApplicationTemplatePage(
    applicationTemplate.id || "",
    () => {
      enqueueSnackbar("Application Template Updated", { variant: "success" })
    },
  )

  const getConfigFormControlValueField = (
    value: boolean,
    name = "",
    label: string,
    props: FormikProps<Page>,
  ) => {
    if (name === "") {
      label = name.replaceAll(" ", "").toLowerCase() + "Value"
    }
    return (
      <FormControlLabel
        control={
          <Switch
            size="small"
            name={name}
            checked={value}
            onChange={(event) => {
              const config = getConfigFromPage(props.values, label) as Config
              if (config) {
                props.setFieldValue(
                  "config",
                  props.values.config.map((c) => {
                    if (c.label === label) {
                      c.value = event.target.checked
                    }
                    return c
                  }),
                )
              } else {
                props.values.config.push({
                  label: label,
                  description: label,
                  value: event.target.checked,
                })
              }
            }}
          />
        }
        label={"Enable " + label}
      />
    )
  }

  const getConfigFormControlRequiredField = (
    value: boolean,
    name: string,
    label: string,
    props: FormikProps<Page>,
  ) => {
    return (
      <FormControlLabel
        control={
          <Switch
            size="small"
            name={name}
            checked={value}
            onChange={(event) => {
              const config = getConfigFromPage(props.values, label) as Config
              if (config) {
                props.setFieldValue(
                  "config",
                  props.values.config.map((c) => {
                    if (c.label === label) {
                      c.required = event.target.checked
                    }
                    return c
                  }),
                )
              } else {
                props.values.config.push({
                  label: label,
                  description: label,
                  required: event.target.checked,
                  value: true,
                })
              }
            }}
          />
        }
        label={"Require " + label}
      />
    )
  }

  return (
    <>
      <Stack sx={{ p: 0.3 }}>
        <Formik
          initialValues={page ? page : ({} as Page)}
          onSubmit={(values) => {
            execute(values, () => {
              refetch()
            })
          }}
        >
          {(props) => (
            <FormControl component="fieldset" variant="standard">
              <Stack spacing={3}>
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      name="enabled"
                      checked={props.values.enabled}
                      onChange={props.handleChange}
                    />
                  }
                  label="Enable Section"
                />
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      name="required"
                      checked={props.values.required as boolean}
                      onChange={props.handleChange}
                    />
                  }
                  label="Require Section"
                />
                <TextField
                  type="number"
                  name="minimumRequired"
                  label="Minimum Required"
                  value={
                    getConfigFromPage(props.values, "Minimum Required")
                      ?.value as number
                  }
                  onChange={(event) => {
                    const config = getConfigFromPage(
                      props.values,
                      "Minimum Required",
                    ) as Config
                    if (config) {
                      props.setFieldValue(
                        "config",
                        props.values.config.map((c) => {
                          if (c.label === "Minimum Required") {
                            c.value = Number(event.target.value)
                          }
                          return c
                        }),
                      )
                    } else {
                      props.values.config.push({
                        label: "Minimum Required",
                        description: "Minimum number of required references",
                        value: Number(event.target.value),
                      })
                    }
                  }}
                />
                {getConfigFormControlValueField(
                  getConfigFromPage(
                    props.values,
                    TRADE_REFERENCE_CONFIGS.FAX_NUMBER,
                  )?.value as boolean,
                  "faxNumberValue",
                  TRADE_REFERENCE_CONFIGS.FAX_NUMBER,
                  props,
                )}
                {getConfigFormControlRequiredField(
                  getConfigFromPage(
                    props.values,
                    TRADE_REFERENCE_CONFIGS.FAX_NUMBER,
                  )?.required as boolean,
                  "faxNumberValue",
                  TRADE_REFERENCE_CONFIGS.FAX_NUMBER,
                  props,
                )}
              </Stack>
              <Stack sx={{ marginY: 2 }}>
                <Button
                  variant="contained"
                  disabled={isLoading}
                  onClick={() => {
                    props.handleSubmit()
                  }}
                >
                  Save
                </Button>
              </Stack>
            </FormControl>
          )}
        </Formik>
        {page && (
          <SectionWhenComponent
            page={page}
            template={applicationTemplate}
            refetch={refetch}
          />
        )}
      </Stack>
    </>
  )
}
