import React, { useCallback, useState } from "react"
import {
  Button,
  useTheme,
  Divider,
  Typography,
  Theme,
  Chip,
  Card,
  Stack,
  Box,
  IconButton,
  Tooltip,
  Popover,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  Table,
  TableCell,
  TableBody,
} from "@mui/material"
import {
  CreditApplication,
  TradeReference,
  TradeReferenceContactReassign,
} from "src/types"
import { useTradeReferences } from "src/queries/credit/useTradeReferences"
import AddNewTradeReferenceDialog from "./AddNewTradeReferenceDialog"
import { useEmailStatus } from "src/queries/vendors/useEmailStatus"
import { confirm } from "src/components/confirm"

import {
  EditOutlined,
  ExpandMore,
  ForwardToInboxOutlined,
  MoreVertOutlined,
  PersonSearchOutlined,
  UnsubscribeOutlined
} from "@mui/icons-material"
import { formatCurrency } from "src/utils/formatNumber"
import TimelineStepper from "../../components/TimelineStepper"
import RequestReferenceDialog from "./RequestReferenceDialog"
import EditTradeReferenceDialog from "./EditTradeReferenceDialog"
import {
  Step,
  useTradeReferenceTimeline,
} from "src/queries/credit/useTradeReferenceTimeline"

import { styled } from "@mui/material/styles"
import { EmailWithValidation } from "./EmailWithValidation"
import { useTradeReferenceTemplate } from "src/queries/credit/useTradeReferenceTemplate"
import { useDeleteTradeReferenceRequest } from "src/queries/credit/useDeleteTradeReferenceRequest"
import { enqueueSnackbar } from "notistack"
import { LoadingButton } from "@mui/lab"
import { TRADE_REFERENCE_ALTERNATE_STATUS } from "src/statics"
import Label from "src/components/label"
import { usePostReassignTradeReferenceContact } from "src/queries/credit/usePostReassignTradeReferenceContact"
import { freeDomains } from "src/queries/vendors/useValidateDomain"
import { usePatchTradeReferenceAlternativeContacts } from "src/queries/credit/usePatchTradeReferenceAlternativeContacts"

interface TradeReferenceCardProps {
  application: CreditApplication
  reference: TradeReference
  theme: Theme
  hasBounced: (email?: string) => boolean
  index: number
  refetch: any
}

const AlternateContactChip = styled(Chip)(() => ({
  backgroundColor: "rgba(25, 44, 32, 0.1)",
  color: "#00C853",
  borderRadius: "16px",
  fontWeight: "bold",
  marginLeft: "8px",
}))

const ReceivedChip = styled(Chip)(() => ({
  backgroundColor: "rgba(0, 200, 83, 0.1)",
  color: "#00C853",
  borderRadius: "16px",
  fontWeight: "bold",
  marginLeft: "8px",
}))

const ReminderSentChip = styled(Chip)(() => ({
  backgroundColor: "rgba(255, 152, 0, 0.1)",
  color: "#FF9800",
  borderRadius: "16px",
  fontWeight: "bold",
  marginLeft: "8px",
}))

const RequestedChip = styled(Chip)(() => ({
  backgroundColor: "rgba(158, 158, 158, 0.1)",
  color: "#616161",
  borderRadius: "16px",
  fontWeight: "bold",
  marginLeft: "8px",
}))

const TradeReferenceCard: React.FC<TradeReferenceCardProps> = ({
  application,
  reference,
  theme,
  hasBounced,
  index,
  refetch,
}) => {
  const [open, setOpen] = useState<TradeReference | null>(null)

  const [add, setAdd] = useState<TradeReference | undefined>(undefined)
  const [request, setRequest] = useState<TradeReference | undefined>(undefined)

  const { data: template } = useTradeReferenceTemplate(application.seller?.id)

  const { execute: executeGetTradeReferenceAlternativeContacts } =
    usePatchTradeReferenceAlternativeContacts(() => {
      refetch()
      refetchSteps()
      enqueueSnackbar(
        "Trade Reference Alternative Contacts Updated successfully",
        {
          variant: "success",
        },
      )
    })

  const {
    execute: reassignTradeReferenceContact,
    isLoading: isLoadingTradeReferenceReassigned,
  } = usePostReassignTradeReferenceContact(() => {
    refetch()
    refetchSteps()
    enqueueSnackbar("Reference reassigned successfully", {
      variant: "success",
    })
  })

  const handleCloseMenu = () => {
    setOpen(null)
  }

  const {
    data,
    isLoading,
    error,
    refetch: refetchSteps,
  } = useTradeReferenceTimeline(reference.id, application.id)

  const { execute: deleteReminders } = useDeleteTradeReferenceRequest(() => {
    refetch()
    refetchSteps()
    enqueueSnackbar(
      "Scheduled reminders will be cancelled. This will take effect shortly.",
      { variant: "success" },
    )
  })

  const displayAlternateContactStatus = (status: string) => {
    if (status === TRADE_REFERENCE_ALTERNATE_STATUS.ORIGINAL) {
      return <Label color={"success"}>Original Reference</Label>
    } else if (
      status === TRADE_REFERENCE_ALTERNATE_STATUS.ALTERNATE_PREVIOUSLY_SELECTED
    ) {
      return <Label color={"warning"}>Previously Selected</Label>
    }
  }

  const getChip = (active: number, allSteps: Step[]) => {
    if (active === 0) return <RequestedChip label="Not Requested" />

    if (allSteps[active - 1].label === "Reminder") {
      return <ReminderSentChip label="Reminder Sent" />
    }

    if (allSteps[active - 1].label === "Received") {
      return <ReminderSentChip label="Received" />
    }

    if (allSteps[active - 1].label === "Requested") {
      return <RequestedChip label="Requested" />
    }

    if (allSteps[active - 1].label === "Not Requested") {
      return <RequestedChip label="Not Requested" />
    }
  }

  const getAlternateContactAvaliableChip = (allSteps: Step[]) => {
    if (
      !reference.isFilled &&
      reference.alternateEmails &&
      reference.alternateEmails.length === 0 &&
      reference.email &&
      typeof reference.email === "string" &&
      reference.email.split("@").length > 0 &&
      !freeDomains.includes(reference.email.split("@")[1]) &&
      allSteps &&
      allSteps.length > 0
    ) {
      const results = allSteps.filter(
        (step) => step.label === "Reminder" && step.completed,
      )
      if (results && results.length >= 3) {
        return <AlternateContactChip label="Alternate Contacts Avaliable" />
      }
    }
  }

  if (isLoading) return <div>Loading...</div>

  const mapValues = (key: string, r: TradeReference) => {
    const v = r[key as keyof TradeReference]
    if (!v) {
      return "---"
    }
    if (key === "terms") {
      return v === "0" ? "Cash" : `Net ${v}`
    }

    if (template?.fields?.find((f) => f.key === key)?.type === "dollar") {
      return `${formatCurrency(v, r.currency)}`
    }

    if (
      ["avgDaysToRepay", "avgDaysBeyondTerms", "paymentStatus"].includes(key)
    ) {
      if (["current", "cash"].includes(v as string)) {
        return v
      }
      return `${v} days`
    }
    return v
  }

  const { timeline: STEPS = [], activeStep = 0 } = data || {}

  return (
    <Card>
      <Stack spacing={3} sx={{ p: 3, typography: "body2" }}>
        {hasBounced(reference?.email) && (
          <Box
            style={{
              border: "solid",
              borderWidth: "2px",
              borderRadius: "16px",
              padding: "8px",
              borderColor: theme.palette.error.main,
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              gap: "8px",
            }}
          >
            <Typography style={{ wordBreak: "break-word" }}>
              Deliverability warning: our systems indicate that emails sent to
              this address have previously been rejected. Please verify the
              email address in case the reference request is not fulfilled in
              time.
            </Typography>
          </Box>
        )}

        <Box
          style={{
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          {reference && STEPS && (
            <Box>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography variant="h5" gutterBottom>
                    Reference {index}
                  </Typography>
                  {getChip(activeStep, STEPS)}
                  {STEPS[activeStep - 1].label === "Reminder" && (
                    <Chip
                      color="warning"
                      style={{
                        borderRadius: "16px",
                        fontWeight: "bold",
                        marginLeft: "8px",
                      }}
                      label={
                        <Box
                          style={{
                            display: "flex",
                            gap: "8px",
                            alignItems: "center",
                          }}
                        >
                          <PersonSearchOutlined /> Trying Another Contact...
                        </Box>
                      }
                    />
                  )}
                  {getAlternateContactAvaliableChip(STEPS)}
                </Box>
                {reference && (
                  <IconButton
                    id={"more-" + reference?.id}
                    size="large"
                    color="inherit"
                    onClick={() => setOpen(reference)}
                  >
                    <MoreVertOutlined />
                  </IconButton>
                )}
              </Box>

              <Stack spacing={2}>
                <Typography variant="h6">Reference Info</Typography>

                <Box
                  display="grid"
                  gridTemplateColumns="1fr 1fr"
                  gap="8px"
                  alignItems="center"
                  justifyItems="start"
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    width="60%"
                  >
                    {/* Vendor Name */}
                    <Box>
                      <Typography variant="body2" style={{ color: "#637381" }}>
                        Vendor Name
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="body2" align="left">
                        {reference.name}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    width="70%"
                  >
                    {/* Email */}
                    <Box>
                      <Typography variant="body2" style={{ color: "#637381" }}>
                        Email
                      </Typography>
                    </Box>
                    <Box>
                      <EmailWithValidation email={reference.email} />
                    </Box>
                  </Box>

                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    width="60%"
                  >
                    {/* Phone Number */}
                    <Box>
                      <Typography variant="body2" style={{ color: "#637381" }}>
                        Phone number
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="body2" align="left">
                        {reference.phoneNumber}
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    width="60%"
                  >
                    {/* Fax Number */}
                    <Box>
                      <Typography variant="body2" style={{ color: "#637381" }}>
                        Fax number
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="body2" align="left">
                        {reference.faxNumber}
                      </Typography>
                    </Box>
                  </Box>
                  {/* Account Number */}
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    width="60%"
                  >
                    <Box>
                      <Typography variant="body2" style={{ color: "#637381" }}>
                        Account number
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="body2" align="left">
                        {reference.accountNumber
                          ? `Account # ${reference.accountNumber}`
                          : "No Account"}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                {!reference.isFilled &&
                  reference.alternateEmails &&
                  reference.alternateEmails.length > 0 && (
                    <Accordion defaultExpanded>
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls={"alternate-content-" + { index }}
                        id={"panel" + { index } + "-header"}
                        sx={{ mt: 1, mb: 1 }}
                      >
                        <Typography variant="subtitle2">
                          Alternative Contacts Avaliable
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <TableContainer component={Paper}>
                          <Table
                            sx={{ minWidth: 650 }}
                            aria-label="simple table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell align="left">Title</TableCell>
                                <TableCell align="left">Email</TableCell>
                                <TableCell align="center">Action</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {reference.alternateEmails.map(
                                (contact, index) => (
                                  <TableRow
                                    key={index}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <TableCell component="th" scope="row">
                                      {contact.name}
                                    </TableCell>
                                    <TableCell align="left">
                                      {displayAlternateContactStatus(
                                        contact.type,
                                      )}
                                    </TableCell>
                                    <TableCell align="left">
                                      {contact?.title}
                                    </TableCell>
                                    <TableCell align="left">
                                      {contact?.email}
                                    </TableCell>
                                    <TableCell align="center">
                                      {contact.email === reference.email && (
                                        <LoadingButton
                                          color="primary"
                                          variant="contained"
                                          disabled
                                          onClick={() => {
                                            console.log("selected")
                                          }}
                                        >
                                          Selected Contact
                                        </LoadingButton>
                                      )}
                                      {!(contact.email === reference.email) && (
                                        <LoadingButton
                                          color="primary"
                                          variant="outlined"
                                          disabled={
                                            isLoadingTradeReferenceReassigned
                                          }
                                          onClick={() => {
                                            reassignTradeReferenceContact({
                                              application_id: application.id,
                                              reference_id: reference.id,
                                              email: contact.email,
                                            } as TradeReferenceContactReassign)
                                          }}
                                        >
                                          Select Contact
                                        </LoadingButton>
                                      )}
                                    </TableCell>
                                  </TableRow>
                                ),
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </AccordionDetails>
                    </Accordion>
                  )}
                <Typography variant="h6">Trade Reference Response</Typography>

                <Box
                  display="grid"
                  gridTemplateColumns="1fr 1fr"
                  gap="8px"
                  alignItems="center"
                  justifyItems="start"
                >
                  {template?.fields
                    ?.filter(
                      (field) =>
                        !["currency", "comments", "reviewItems"].includes(
                          field.key,
                        ),
                    )
                    .map((field) => (
                      <Tooltip
                        title={field.description}
                        arrow
                        key={field.label}
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          width="60%"
                        >
                          <Box>
                            <Typography
                              variant="body2"
                              style={{ color: "#637381" }}
                            >
                              {field.label}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant="body2">
                              {mapValues(field.key, reference) as string}
                            </Typography>
                          </Box>
                        </Box>
                      </Tooltip>
                    ))}
                </Box>
              </Stack>
            </Box>
          )}
        </Box>
        {reference && (
          <Box
            style={{
              border: "solid",
              borderWidth: "2px",
              borderRadius: "16px",
              padding: "8px",
              borderColor: theme.palette.primary.main,
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              gap: "8px",
            }}
          >
            <Typography variant="subtitle1" style={{ wordBreak: "break-word" }}>
              Considerations:
            </Typography>
            <Typography style={{ wordBreak: "break-word" }}>
              {reference.reviewItems && reference.reviewItems.length > 0
                ? reference.reviewItems.join(", ")
                : "None"}
            </Typography>

            <Typography variant="subtitle1" style={{ wordBreak: "break-word" }}>
              Comments:
            </Typography>
            <Typography style={{ wordBreak: "break-word" }}>{`${
              reference.comments || "None"
            }`}</Typography>
          </Box>
        )}
        {!error &&
          !isLoading &&
          STEPS &&
          activeStep > 0 &&
          STEPS[activeStep - 1].label !== "Not Requested" && (
            <TimelineStepper timeline={STEPS} activeStep={activeStep} />
          )}

        {open && (
          <Popover
            open={Boolean(open)}
            anchorEl={document.getElementById("more-" + open.id)}
            onClose={handleCloseMenu}
            anchorOrigin={{ vertical: "top", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            PaperProps={{
              sx: {
                p: 1,
                "& .MuiMenuItem-root": {
                  px: 1,
                  typography: "body2",
                  borderRadius: 0.75,
                },
              },
            }}
          >
            <MenuItem
              onClick={() => {
                setAdd(open)
                setOpen(null)
              }}
            >
              <Box style={{ display: "flex", gap: "1rem" }}>
                <EditOutlined />
                View / Edit
              </Box>
            </MenuItem>

            {!reference.isFilled &&
              reference.email &&
              typeof reference.email === "string" &&
              reference.email.split("@").length > 0 &&
              !freeDomains.includes(reference.email.split("@")[1]) &&
              (!reference.alternateEmails ||
                (reference.alternateEmails &&
                  reference.alternateEmails.length == 0)) && (
                <MenuItem
                  onClick={() => {
                    confirm(
                      "Are you sure you want to get alternate contacts for this reference?",
                    )
                      .then(
                        () => {
                          executeGetTradeReferenceAlternativeContacts({
                            reference_id: reference.id,
                            application_id: application.id,
                          } as TradeReferenceContactReassign)
                          return setOpen(null)
                        },
                        () => {
                          setOpen(null)
                        },
                      )
                      .catch((e: any) => {
                        console.log(e)
                      })
                  }}
                >
                  <Box style={{ display: "flex", gap: "1rem" }}>
                    <PersonSearchOutlined />
                    Get Alternate Contacts
                  </Box>
                </MenuItem>
              )}
            <MenuItem
              onClick={() => {
                setRequest(open)
                setOpen(null)
              }}
            >
              <Box style={{ display: "flex", gap: "1rem" }}>
                <ForwardToInboxOutlined />
                Send Request for Reference
              </Box>
            </MenuItem>
            <MenuItem
              onClick={() => {
                confirm(
                  "Are you sure you want to stop reminders for this reference?",
                )
                  .then(
                    () => {
                      deleteReminders(open.id)
                      return setOpen(null)
                    },
                    () => {
                      setOpen(null)
                    },
                  )
                  .catch((e: any) => {
                    console.log(e)
                  })
              }}
            >
              <Box style={{ display: "flex", gap: "1rem" }}>
                <UnsubscribeOutlined />
                Stop Reminders
              </Box>
            </MenuItem>
          </Popover>
        )}

        {request && (
          <RequestReferenceDialog
            reference={request}
            open={!!request}
            onClose={() => {
              setRequest(undefined)
              refetchSteps()
            }}
          />
        )}

        {add && (
          <EditTradeReferenceDialog
            reference={add}
            open={!!add}
            onClose={() => {
              setAdd(undefined)
              refetch()
            }}
          />
        )}
      </Stack>
    </Card>
  )
}

export default ({ application }: { application: CreditApplication }) => {
  const { data: tradeRefs, refetch } = useTradeReferences(application.id)

  const theme = useTheme()
  const [createNewReference, setCreateNewReference] = useState(false)

  const { data: emailStatus } = useEmailStatus(
    tradeRefs?.map((r) => r.email) || [],
    application.id || "",
  )

  const hasBounced = useCallback(
    (email?: string) => {
      if (!email) return false
      return emailStatus
        ?.find((response: any) => response.email === email)
        ?.messages?.find(
          (e: any) => e.events?.find((ev: any) => ev.eventName === "bounce"),
        )
    },
    [emailStatus],
  )

  const newRefButton = (
    <Button
      variant="contained"
      onClick={() => {
        setCreateNewReference(true)
      }}
      fullWidth
      style={{
        height: "50px",
        marginTop: "20px",
        backgroundColor: "#919EAB29",
        color: "black",
        border: "1px solid black",
        borderRadius: "12px", // Ensure text is readable on the background
      }}
    >
      + New Reference
    </Button>
  )

  return (
    <>
      {tradeRefs?.map((v, i) => (
        <React.Fragment key={i}>
          <TradeReferenceCard
            application={application}
            reference={v}
            hasBounced={hasBounced}
            theme={theme}
            index={i + 1}
            refetch={refetch}
          />
          {i < tradeRefs.length - 1 && <Divider style={{ marginTop: "8px" }} />}
        </React.Fragment>
      ))}
      {tradeRefs?.length === 0 && (
        <Typography
          variant="subtitle1"
          style={{ marginTop: "20px", marginLeft: "20px" }}
        >
          No trade references added
        </Typography>
      )}
      {newRefButton}
      {createNewReference && application.id && (
        <AddNewTradeReferenceDialog
          applicationId={application.id}
          onClose={() => {
            setCreateNewReference(false)
            refetch()
          }}
          open={createNewReference}
        />
      )}
    </>
  )
}
