import { QueryClient } from "react-query"
import { useTypedMutation } from "../../useTypedMutation"

export function usePatchAdditionalDataRequestV3(
  requestId?: string,
  onSuccess?: () => void,
) {
  const ENDPOINT = "/application/v3/data_request/"
  const patch = useTypedMutation<FormData>(
    "post/application/v3/data_request",
    onSuccess,
  )

  const execute = (
    applicationId: string,
    signerFirst: string,
    signerLast: string,
    signerRole: string,
    fingerprintRequestId: string,
    b64Signature: string,
    pdf?: File,
    onSuccess?: () => void,
  ) => {
    if (!requestId) return
    const formData = new FormData()
    if (pdf) {
      formData.append("file_0", pdf)
    }
    formData.append("id", applicationId)
    formData.append("signerFirst", signerFirst)
    formData.append("signerLast", signerLast)
    formData.append("signerRole", signerRole)
    formData.append("b64Signature", b64Signature)
    formData.append("visitorRequestId", fingerprintRequestId)
    patch.mutate(
      {
        method: "patch",
        endpoint: ENDPOINT + requestId + "/",
        body: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
      {
        onSuccess: async () => {
          await new QueryClient().invalidateQueries({
            queryKey: [ENDPOINT.replaceAll("/", "")],
          })
          if (onSuccess) onSuccess()
        },
      },
    )
  }

  return { ...patch, execute }
}
