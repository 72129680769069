import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
} from "@mui/material"
import { useEffect } from "react"

import { Outlet } from "react-router-dom"
import { useLocation } from "react-router-dom"
import { useAnonymousBusiness } from "../../queries/credit/useAnonymousBusiness"
import { loadLogoIntoImg } from "../../utils/imageTools"
import { DynamicThemeProvider } from "../../theme"
import { getDisplayContent } from "src/utils/form-content"
// ----------------------------------------------------------------------

export default function NoAuthSimpleLayout() {
  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const businessId = queryParams.get("business_id") || ""

  const { data } = useAnonymousBusiness(businessId)
  const displayContent = getDisplayContent(
    queryParams.get("request_type") || "",
  )

  const logoOverride = queryParams.get("logo")

  useEffect(() => {
    loadLogoIntoImg(
      document.getElementById("company-logo-desktop"),
      logoOverride || data?.logo,
    )
  }, [logoOverride, data])

  const matches = useMediaQuery("(max-width: 1199px)")
  if (!data)
    return (
      <Box
        style={{
          backgroundColor: "#fff",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          width: "100%",
        }}
      >
        <CircularProgress color="info" />
      </Box>
    )

  return (
    <DynamicThemeProvider>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}></Box>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <Grid
          item
          lg={4}
          xl={4}
          style={{
            backgroundColor: "#fff",
            display: matches ? "none" : "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            width: "100%",
          }}
          sx={{ height: "auto" }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: "10%",
              alignContent: "center",
            }}
          >
            <Box
              style={{
                display: "flex",
                padding: "10% 25% 20% 25%",
                justifyContent: "center",
              }}
            >
              <img id="company-logo-desktop" />
            </Box>
            <Typography variant="h4">{displayContent}</Typography>
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "16px",
              bottom: "5%",
              position: "absolute",
            }}
          >
            <Box
              display={{ xs: "none", lg: "flex", xl: "flex" }}
              style={{
                flexDirection: "row",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <img src="/assets/soc2.png" style={{ width: "72px" }} />
              <img src="/assets/ssl.png" style={{ height: "72px" }} />
            </Box>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                gap: "4px",
              }}
            >
              <Typography sx={{ fontWeight: "bold" }}>powered by</Typography>
              <img src="/assets/logo-simple.png" style={{ width: "32px" }} />
              <Typography sx={{ fontWeight: "bold" }}>NetNow</Typography>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={8}
          xl={8}
          component={Paper}
          elevation={6}
          square
          sx={{ width: matches ? "100%" : "auto" }}
        >
          <Outlet />
        </Grid>
      </Grid>
    </DynamicThemeProvider>
  )
}
