import { useState, useEffect } from "react"
import { useQuery } from "react-query"
import { AdditionalDataRequest, ApplicationTemplate } from "src/types"
import * as yup from "yup"
import { getTradeEnabledSchema } from "src/sections/@dashboard/intake_sections/schemas/TradeReferenceSectionSchema"
import { getBankEnabledSchema } from "src/sections/@dashboard/intake_sections/schemas/BankReferenceSectionSchema"
import { getOwnersEnabledSchema } from "src/sections/@dashboard/intake_sections/schemas/OwnersSectionSchema"
import { getCompanyDetailsEnabledSchema } from "src/sections/@dashboard/intake_sections/schemas/CompanyDetailsSectionSchema"
import { getPersonalGuarantyEnabledSchema } from "src/sections/@dashboard/intake_sections/schemas/PersonalGuarantySectionSchema"
import { getAdditionalQuestionsSchema } from "src/sections/@dashboard/intake_sections/schemas/CustomQuestionsSectionsSchema"
import queryString from "query-string"
import {
  PAGE_LABEL_ADDITIONAL_QUESTIONS,
  PAGE_LABEL_BANK_REFERENCE,
  PAGE_LABEL_BUSINESS_DETAILS,
  PAGE_LABEL_OWNERS,
  PAGE_LABEL_PERSONAL_GUARANTY,
  PAGE_LABEL_TRADE_REFERENCES,
} from "src/sections/@dashboard/intake_sections/constants"
import { useAnonymousBusiness } from "../useAnonymousBusiness"
import { BUSINESS_PREFERENCES, usePreference } from "src/hooks/use-preference"

export function useAdditionalDataRequest(
  requestId?: string,
  businessId?: string,
) {
  const [steps, setSteps] = useState<
    ApplicationTemplate["formTemplate"]["pages"]
  >([])

  const { data: business } = useAnonymousBusiness(businessId || "")

  const { preference: signatureV3Enabled } = usePreference(
    BUSINESS_PREFERENCES.SIGNATURE_V3,
    business,
  )

  const [template, setTemplate] = useState<ApplicationTemplate>()

  const [dataSchema, setDataSchema] = useState(yup.object())

  const { data: dataRequest } = useQuery<AdditionalDataRequest>(
    `/application/base/data_request/${requestId}`,
    {
      enabled: !!requestId,
      retry: (failureCount, error) => {
        if (failureCount < 1) {
          return true
        }
        // do not retry on 400, 401, 403
        return ![400, 401, 403].includes((error as any)?.response?.status)
      },
    },
  )

  const { data: baseTemplate, ...rest } = useQuery<ApplicationTemplate>(
    queryString.stringifyUrl({
      url: "/configure/template/1",
      query: { business_id: businessId },
    }),
    {
      enabled: businessId !== undefined,
      retry: (failureCount, error) => {
        if (failureCount < 1) {
          return true
        }
        // do not retry on 404
        return (error as any)?.response?.status !== 404
      },
      meta: { anonymous: true },
    },
  )

  // does three things
  // 1. loads main steps
  // 2. adds additional questions step if custom fields are present
  // 3. adds signature step if signatureV3 is enabled
  useEffect(() => {
    if (dataRequest && baseTemplate && steps.length === 0) {
      const newTemplate = { ...baseTemplate }
      newTemplate.formTemplate.pages = dataRequest.pages

      let tempSchema = dataSchema
      // tempSchema = tempSchema.concat(getUserEnabledSchema(newTemplate))
      if (
        newTemplate.formTemplate.pages.find(
          (p) => p.label === PAGE_LABEL_TRADE_REFERENCES,
        )
      ) {
        tempSchema = tempSchema.concat(getTradeEnabledSchema(newTemplate))
      }
      if (
        newTemplate.formTemplate.pages.find(
          (p) => p.label === PAGE_LABEL_BANK_REFERENCE,
        )
      ) {
        tempSchema = tempSchema.concat(getBankEnabledSchema(newTemplate))
      }
      if (
        newTemplate.formTemplate.pages.find(
          (p) => p.label === PAGE_LABEL_PERSONAL_GUARANTY,
        )
      ) {
        tempSchema = tempSchema.concat(
          getPersonalGuarantyEnabledSchema(newTemplate),
        )
      }

      if (
        newTemplate.formTemplate.pages.find(
          (p) => p.label === PAGE_LABEL_OWNERS,
        )
      ) {
        tempSchema = tempSchema.concat(getOwnersEnabledSchema(newTemplate))
      }
      if (
        newTemplate.formTemplate.pages.find(
          (p) => p.label === PAGE_LABEL_BUSINESS_DETAILS,
        )
      ) {
        tempSchema = tempSchema.concat(
          getCompanyDetailsEnabledSchema(newTemplate),
        )
      }
      if (
        newTemplate.formTemplate.pages.find(
          (p) => p.label === PAGE_LABEL_ADDITIONAL_QUESTIONS,
        )
      ) {
        tempSchema = tempSchema.concat(
          getAdditionalQuestionsSchema(newTemplate),
        )
      }

      let tempSteps = dataRequest.pages

      if (
        signatureV3Enabled &&
        dataRequest?.requestSignature &&
        tempSteps.find((tstep) => tstep.label === "Review and Sign") ===
          undefined
      ) {
        tempSteps = tempSteps.concat([
          {
            label: "Review and Sign",
            description: "Review and Sign",
            enabled: true,
            required: true,
            config: [],
          },
        ])
      }
      setDataSchema(tempSchema)
      setSteps(tempSteps)
      setTemplate(newTemplate)
      return
    }
  }, [steps, baseTemplate, dataSchema, dataRequest, signatureV3Enabled])

  return { data: template, dataRequest, dataSchema, steps, ...rest }
}
