import { Box, Grid } from "@mui/material"
import LoadingButton from "@mui/lab/LoadingButton"
import AddOrganizationForm from "../sections/@dashboard/business/AddOrganizationForm"
import { useCreateSeller } from "../queries/base/useCreateSeller"
import { useUser } from "../queries"
import { useBuyerQuickLinks } from "src/queries/credit/useBuyerQuickLinks"
import BuyerApplicationListRow from "src/sections/@dashboard/credit/list/BuyerApplicationListRow"
import BuyerAdditionalDataRequestListRow from "src/sections/@dashboard/credit/list/BuyerAdditionalDataRequestListRow"
import { CreditApplication } from "src/types"

export default () => {
  const { data, auth0User } = useUser()

  const createSeller = useCreateSeller(() => {
    window.location.href = "/settings"
    // executeCreateRailz()
  })
  const { data: quickLinks } = useBuyerQuickLinks(!!data)

  if (!data || !auth0User) return <>Loading...</>

  return (
    <Grid
      container
      direction="column"
      style={{ padding: "10px", alignItems: "center" }}
    >
      <h2>Thank you for using NetNow.</h2>
      {/* <h4>
        Your account is not associated with any organization. Please contact
        your account executive if you are our client.
      </h4> */}
      {data.isSuperuser && (
        <Grid
          item
          direction="column"
          style={{ padding: "0 10% 0 10%", alignItems: "center" }}
        >
          <AddOrganizationForm
            mutation={createSeller}
            defaults={{ email: auth0User?.email || data?.email }}
          >
            <LoadingButton
              loading={createSeller.isLoading}
              variant="contained"
              color="primary"
              size="large"
              style={{ width: "100%", marginTop: "1rem" }}
              type="submit"
            >
              Continue
            </LoadingButton>
          </AddOrganizationForm>
        </Grid>
      )}
      {quickLinks &&
        (quickLinks.applications.length > 0 ||
          quickLinks.requests.length > 0) && (
          <Grid
            item
            direction="column"
            style={{ padding: "0 10% 0 10%", alignItems: "center" }}
          >
            {/* <h4>
              You have {quickLinks.requests.length} pending data request(s):
            </h4> */}

            <h4>
              Please see your current or pending credit applications below:
            </h4>
            {quickLinks.requests.length > 0 &&
              quickLinks.requests.map((req, index) => {
                if (
                  !quickLinks.applications.filter(
                    (a) => a.id === req.application,
                  )
                ) {
                  return <></>
                }
                return (
                  <BuyerAdditionalDataRequestListRow
                    row={req}
                    application={
                      quickLinks.applications.find(
                        (a) => a.id === req.application,
                      ) as CreditApplication
                    }
                    key={index}
                    index={index}
                  />
                )
              })}
            {quickLinks.applications.length > 0 &&
              quickLinks.applications
                .filter((a) => ![50, 60].includes(a.stage))
                .map((application, index) => {
                  return (
                    <Box
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <BuyerApplicationListRow
                        row={application}
                        key={index}
                        index={index}
                      />
                    </Box>
                  )
                })}
          </Grid>
        )}
    </Grid>
  )
}
